.main-portfolio{
    background-color: black;
    width: 100%;
    height: auto;
    color:white;
    margin-bottom: 2%;
}
.portfolio-introduction{
    text-align: center;
    margin: 5% 1% 10% 1%;
    
}
.portfolio-introduction h3{
    font-style: italic;
}
.portfolio-introduction p{
    font-style: italic;
}
.portfolio-category{
    text-align: center;
    margin-bottom: 1%;
}
.portfolio-category h2{
    color: #44AD9A;
    font-weight: bold;
    font-size: 350%;
}
.portfolio-single{
    display: block;
    border-width: 1px;
    border-style: solid;
    border-color: gray;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
}
.portfolio-single-img{
    width: 100%;
    max-width: 40%;
    height: auto;
    border-radius: 10px;
}
.portfolio-single-download-app-link{
    border-radius: 5px;
    margin-left: 1%;
    width: 10%;
}