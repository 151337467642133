.main-contact{
    background-color: black;
    width: 100%;
    height: auto;
    color:white;
    margin-bottom: 2%;
    text-align: center;
}
.tree-contacts-info{
    margin-top: 10%;
}
.whatsapp-icon-contact{
    width: 25px;
    margin-left: 1%;
}
.get-tree-contact-block{
    display: block;
    margin-top: 10%;
}
input{
    margin: 1%;
}
textarea{
    margin: 1%;
}
.confirm-button {
    background-color: #44AD9A;
    color: white;
    border-radius: 3%;
}
#contactDescription {
    height: 5%;
}
.fill-empty-contact{
    width: 100%;
    height: 350px;  
}